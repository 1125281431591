import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import type { MenuSettings } from '@models/menu-settings/menu-settings';
import type { AvailableParents } from '@models/partner/menu/available-parents-response';
import type { SiblingsLockedValues } from '@models/partner/menu/menu-icons';
import type { CreateNavigationItemRequest, NavigationItemResponse } from '@models/partner/menu/navigation-item';
import type { OptionSelectInputResources } from '@models/partner/menu/option-select-input-resource';
import type {
  MenuType,
  NavigationType,
  OrderingItemWithChildren,
  PlanGroupMenuResponse,
} from '@models/partner/menu/plan-group-menu-response';
import type { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuWebservice {
  readonly #http = inject(HttpClient);
  readonly #baseUrl = `${environment.api.obendy}/v1/partner-revision`;

  public readonly getSettings = (partnerId: string): Observable<MenuSettings> =>
    this.#http.get<MenuSettings>(`${this.#baseUrl}/${partnerId}/menu-settings`);

  public readonly getPlanGroupMenu = ({
    partnerId,
    planGroupId,
    menuType,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
  }): Observable<PlanGroupMenuResponse> =>
    this.#http.get<PlanGroupMenuResponse>(
      `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}`
    );

  public readonly deleteMenuEntry = ({
    partnerId,
    planGroupId,
    menuType,
    entryId,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    entryId: string;
  }): Observable<void> =>
    this.#http.delete<undefined>(
      `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/menu-item/${entryId}`
    );

  public readonly reorderMenu = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    ordering,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    ordering: Array<OrderingItemWithChildren>;
  }): Observable<PlanGroupMenuResponse> =>
    this.#http.patch<PlanGroupMenuResponse>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}/items-reordering`,
      { ordering }
    );

  public readonly getInternalRoutes = ({
    partnerId,
    planGroupId,
  }: {
    partnerId: string;
    planGroupId: string;
  }): Observable<OptionSelectInputResources> =>
    this.#http.get<OptionSelectInputResources>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/internal-links-select`
    );

  public readonly getNavigationItem = ({
    partnerId,
    planGroupId,
    menuEntryId,
    menuType,
  }: {
    partnerId: string;
    planGroupId: string;
    menuEntryId: string;
    menuType: MenuType;
  }): Observable<NavigationItemResponse> =>
    this.#http.get<NavigationItemResponse>(
      `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/item/${menuEntryId}`
    );

  public readonly getSiblingLockedValues = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    itemId = null,
    parentId = null,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    itemId: string | null;
    parentId: string | null;
  }): Observable<SiblingsLockedValues> => {
    const queryParams = new URLSearchParams();

    if (itemId !== null) queryParams.append('itemId', itemId);
    if (parentId !== null) queryParams.append('parentId', parentId);

    const url = `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}/sibling-values?${queryParams.toString()}`;

    return this.#http.get<SiblingsLockedValues>(url);
  };

  public readonly createNavigationItem = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    payload,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    payload: CreateNavigationItemRequest;
  }): Observable<NavigationItemResponse> =>
    this.#http.post<NavigationItemResponse>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}`,
      payload
    );

  public readonly updateNavigationItem = ({
    partnerId,
    planGroupId,
    menuType,
    itemId,
    payload,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    itemId: string;
    payload: CreateNavigationItemRequest;
  }): Observable<NavigationItemResponse> =>
    this.#http.put<NavigationItemResponse>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/item/${itemId}`,
      payload
    );

  public readonly getAvailableParents = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    itemId = null,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    itemId: string | null;
  }): Observable<AvailableParents> => {
    let route = `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}/availableParents`;

    if (itemId !== null) {
      route = `${route}?itemId=${itemId}`;
    }

    return this.#http.get<AvailableParents>(route);
  };
}
