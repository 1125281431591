import type { Route } from '@angular/router';

import { canDeactivateGuard } from '@features/partners/builder/routes/canDeactivate.guard';
import { canActivateDesktopMenuPage } from '@features/partners/builder/settings/menu/routes/guards/can-activate-desktop.guard';
import { canActivateMobileMenuPage } from '@features/partners/builder/settings/menu/routes/guards/can-activate-mobile.guard';
import { canActivateNavigationItem } from '@features/partners/builder/settings/menu/routes/guards/can-activate-navigation-item.guard';
import { availableParentsResolver } from '@features/partners/builder/settings/menu/routes/resolvers/available-parents.resolver';
import { internalRoutesResolver } from '@features/partners/builder/settings/menu/routes/resolvers/internal-routes.resolver';
import { navigationItemResolver } from '@features/partners/builder/settings/menu/routes/resolvers/navigation-item.resolver';
import { siblingsLockedValuesResolver } from '@features/partners/builder/settings/menu/routes/resolvers/siblings-locked-values.resolver';
import { DEFAULT_NAVIGATION_DESKTOP_ITEM, DEFAULT_NAVIGATION_MOBILE_ITEM } from '@models/partner/menu/navigation-item';

export const MENU_ROUTING = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
};

export const MENU_ROUTES: Array<Route> = [
  {
    path: '',
    redirectTo: MENU_ROUTING.DESKTOP,
    pathMatch: 'full',
  },
  {
    path: MENU_ROUTING.DESKTOP,
    title: MENU_ROUTING.DESKTOP,
    children: [
      {
        path: '',
        canActivate: [canActivateDesktopMenuPage],
        loadComponent: async () =>
          (await import('@features/partners/builder/settings/menu/menu-list/menu-list.component')).MenuListComponent,
      },
      {
        path: 'create',
        title: 'Création',
        loadComponent: async () =>
          (await import('@features/partners/builder/settings/menu/navigation-item/navigation-item.component'))
            .NavigationItemComponent,
        data: {
          navigationItem: DEFAULT_NAVIGATION_DESKTOP_ITEM,
        },
        canActivate: [canActivateNavigationItem],
        canDeactivate: [canDeactivateGuard],
        resolve: {
          internalRoutes: internalRoutesResolver,
          siblingsLockedValues: siblingsLockedValuesResolver,
          availableParents: availableParentsResolver,
        },
      },
      {
        path: ':navigationItemId',
        title: 'Modification',
        loadComponent: async () =>
          (await import('@features/partners/builder/settings/menu/navigation-item/navigation-item.component'))
            .NavigationItemComponent,
        canActivate: [canActivateNavigationItem],
        canDeactivate: [canDeactivateGuard],
        resolve: {
          internalRoutes: internalRoutesResolver,
          navigationItem: navigationItemResolver,
          siblingsLockedValues: siblingsLockedValuesResolver,
          availableParents: availableParentsResolver,
        },
      },
    ],
  },
  {
    path: MENU_ROUTING.MOBILE,
    title: MENU_ROUTING.MOBILE,
    children: [
      {
        path: '',
        canActivate: [canActivateMobileMenuPage],
        loadComponent: async () =>
          (await import('@features/partners/builder/settings/menu/menu-list/menu-list.component')).MenuListComponent,
      },
      {
        path: 'create',
        title: 'Création',
        loadComponent: async () =>
          (await import('@features/partners/builder/settings/menu/navigation-item/navigation-item.component'))
            .NavigationItemComponent,
        data: {
          navigationItem: DEFAULT_NAVIGATION_MOBILE_ITEM,
        },
        canActivate: [canActivateNavigationItem],
        canDeactivate: [canDeactivateGuard],
        resolve: {
          internalRoutes: internalRoutesResolver,
          siblingsLockedValues: siblingsLockedValuesResolver,
          availableParents: availableParentsResolver,
        },
      },
      {
        path: ':navigationItemId',
        title: 'Modification',
        loadComponent: async () =>
          (await import('@features/partners/builder/settings/menu/navigation-item/navigation-item.component'))
            .NavigationItemComponent,
        canActivate: [canActivateNavigationItem],
        canDeactivate: [canDeactivateGuard],
        resolve: {
          internalRoutes: internalRoutesResolver,
          navigationItem: navigationItemResolver,
          siblingsLockedValues: siblingsLockedValuesResolver,
          availableParents: availableParentsResolver,
        },
      },
    ],
  },
];
