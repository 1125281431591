import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FormValue } from '@commons/types/form';
import { BUILDER_ROUTING } from '@features/partners/builder/routes';
import { MENU_ROUTING } from '@features/partners/builder/settings/menu/routes/menu.routes';
import { SETTINGS_ROUTING } from '@features/partners/builder/settings/routes';
import { FEATURES_ROUTING } from '@features/routes';
import { RegisterIcon } from '@models/partner/menu/menu-icons';
import {
  CreateNavigationItemRequest,
  LinkResponse,
  NavigationItemResponse,
} from '@models/partner/menu/navigation-item';
import { NavigationItemForm } from '@models/partner/menu/navigation-item-form';
import {
  DESKTOP_MENU_TYPE,
  MAIN_NAVIGATION_TYPE,
  MENU_TYPES,
  MOBILE_MENU_TYPE,
  MenuType,
  NAVIGATION_TYPES,
  NavigationType,
} from '@models/partner/menu/plan-group-menu-response';
import { MenuWebservice } from '@webservices/menu/menu.webservice';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  catchError,
  combineLatest,
  filter,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemService {
  readonly #menuWebService = inject(MenuWebservice);
  readonly #router = inject(Router);

  readonly #partnerId$ = new ReplaySubject<string>(1);
  public readonly setPartnerId = (partnerId: string) => {
    if (typeof partnerId !== 'string') return;
    this.#partnerId$.next(partnerId);
  };
  readonly #partnerId = toSignal(this.#partnerId$, { initialValue: null });

  readonly #navigationItemId$ = new ReplaySubject<string | null>(1);
  public readonly setNavigationItemId = (navigationItemId: string | null) => {
    this.#navigationItemId$.next(navigationItemId);
  };

  readonly #navigationItemId = toSignal(this.#navigationItemId$, { initialValue: null });

  readonly #planGroupId$ = new ReplaySubject<string>(1);
  public readonly setPlanGroupId = (planGroupId: string) => {
    if (typeof planGroupId !== 'string') return;
    this.#planGroupId$.next(planGroupId);
  };

  readonly #menuType$ = new ReplaySubject<MenuType>(1);
  public readonly setMenuType = (_menuType: string) => {
    if (!MENU_TYPES.includes(_menuType as any)) {
      return;
    }
    const validMenuType = _menuType as MenuType;

    this.#menuType$.next(validMenuType);
  };
  readonly #menuType = toSignal(this.#menuType$, { initialValue: null });

  readonly #navigationType$ = new ReplaySubject<NavigationType>(1);
  public readonly setNavigationType = (navigationType: string) => {
    if (!NAVIGATION_TYPES.includes(navigationType as any)) {
      return;
    }
    const validNavigationType = navigationType as NavigationType;
    this.#navigationType$.next(validNavigationType);
  };

  public readonly navigationType = toSignal(this.#navigationType$, { initialValue: null });

  readonly #parentId$ = new BehaviorSubject<string | null>(null);
  public readonly setParentId = (parentId: string | null): void => {
    this.#parentId$.next(parentId);
  };

  public readonly isURLMandatory = computed(
    () => this.#menuType() === MOBILE_MENU_TYPE && this.navigationType() === MAIN_NAVIGATION_TYPE
  );

  readonly navigationItem$ = combineLatest([
    this.#partnerId$,
    this.#navigationItemId$.pipe(filter((id): id is string => Boolean(id))),
    this.#planGroupId$,
    this.#menuType$,
  ]).pipe(
    switchMap(([partnerId, menuEntryId, planGroupId, menuType]) =>
      this.#menuWebService.getNavigationItem({
        partnerId,
        menuEntryId,
        planGroupId,
        menuType,
      })
    )
  );

  readonly siblingLockedValue$ = combineLatest([
    this.#partnerId$,
    this.#planGroupId$,
    this.#menuType$,
    this.#navigationType$,
    this.#navigationItemId$,
    this.#parentId$,
  ]).pipe(
    switchMap(([partnerId, planGroupId, menuType, navigationType, itemId, parentId]) =>
      this.#menuWebService.getSiblingLockedValues({
        partnerId,
        planGroupId,
        menuType,
        navigationType,
        itemId,
        parentId,
      })
    )
  );

  readonly availableParents$ = combineLatest([
    this.#partnerId$,
    this.#planGroupId$,
    this.#menuType$,
    this.#navigationType$,
    this.#navigationItemId$,
  ]).pipe(
    switchMap(([partnerId, planGroupId, menuType, navigationType, itemId]) =>
      this.#menuWebService
        .getAvailableParents({ partnerId, planGroupId, menuType, navigationType, itemId })
        .pipe(catchError(() => of([])))
    )
  );

  public readonly internalRoutes$ = combineLatest([this.#partnerId$, this.#planGroupId$]).pipe(
    switchMap(([partnerId, planGroupId]) => this.#menuWebService.getInternalRoutes({ partnerId, planGroupId })),
    catchError(() => of([]))
  );

  public readonly isInCreation = computed(() => this.#navigationItemId() === null);

  public readonly pageTitle = computed(() =>
    this.isInCreation() ? "Création d'une entrée menu" : "Modification d'une entrée menu"
  );

  public readonly save = (form: NavigationItemForm): Observable<NavigationItemResponse> => {
    const payload$ = this.#forgePayloadFromFormValue(form.value);

    if (this.isInCreation()) {
      return combineLatest([
        payload$,
        this.#partnerId$,
        this.#planGroupId$,
        this.#menuType$,
        this.#navigationType$,
      ]).pipe(
        take(1),
        switchMap(([payload, partnerId, planGroupId, menuType, navigationType]) =>
          this.#menuWebService.createNavigationItem({
            partnerId,
            planGroupId,
            menuType,
            navigationType,
            payload,
          })
        )
      );
    } else {
      return combineLatest([
        payload$,
        this.#partnerId$,
        this.#planGroupId$,
        this.#menuType$,
        this.navigationItem$,
      ]).pipe(
        take(1),
        switchMap(([payload, partnerId, planGroupId, menuType, navigationItem]) =>
          this.#menuWebService.updateNavigationItem({
            partnerId,
            planGroupId,
            menuType,
            itemId: navigationItem.id,
            payload,
          })
        )
      );
    }
  };

  public readonly navigateBack = (): null | Promise<boolean> => {
    const partnerId = this.#partnerId();
    const menuType = this.#menuType();

    if (partnerId === null || menuType === null) return null;
    const menuTypeRoute = menuType == DESKTOP_MENU_TYPE ? MENU_ROUTING.DESKTOP : MENU_ROUTING.MOBILE;

    return this.#router.navigate(
      ['/', FEATURES_ROUTING.Partners, partnerId, BUILDER_ROUTING.Settings, SETTINGS_ROUTING.Menu, menuTypeRoute],
      { queryParamsHandling: 'merge' }
    );
  };

  readonly #forgePayloadFromFormValue = (
    value: FormValue<NavigationItemForm>
  ): Observable<CreateNavigationItemRequest> => {
    const _icon = value.icon === undefined ? null : (value.icon as RegisterIcon);
    const _label = value.label ?? null;
    const _status = value.status ?? null;

    let _link: LinkResponse = null;
    const _parentId = value.parentId === '' ? null : (value.parentId ?? null);

    if (value.externalUrl !== undefined) {
      _link = {
        type: 'external',
        target: value.externalLink ? '_blank' : '_self',
        url: value.externalUrl,
      };
    }

    if (value.internalUrl !== undefined) {
      _link = {
        type: 'internal',
        target: '_self',
        url: value.internalUrl,
      };
    }

    if (_label === null || _status === null) {
      return throwError(() => new Error('Label and status are required fields, but one or both are missing.'));
    }

    return of({ icon: _icon, label: _label, link: _link, status: _status, parentId: _parentId });
  };
}
