import type { ActivatedRouteSnapshot } from '@angular/router';

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItemService } from '@features/partners/builder/settings/menu/navigation-item/navigation-item.service';

export const canActivateNavigationItem = ({ paramMap, queryParamMap }: ActivatedRouteSnapshot) => {
  const navigationItemService = inject(NavigationItemService);
  const router = inject(Router);

  const partnerId = paramMap.get('partnerId');

  if (typeof partnerId !== 'string') {
    return router.createUrlTree(['/']);
  }

  navigationItemService.setPartnerId(partnerId);

  const navigationItemId = paramMap.get('navigationItemId');

  navigationItemService.setNavigationItemId(navigationItemId);

  const planGroupId = queryParamMap.get('planGroupId');
  const menuType = queryParamMap.get('menuType');
  const navigationType = queryParamMap.get('navigationType');

  if (typeof planGroupId !== 'string' || typeof menuType !== 'string' || typeof navigationType !== 'string') {
    return router.createUrlTree(['/']);
  }
  navigationItemService.setPlanGroupId(planGroupId);
  navigationItemService.setMenuType(menuType);
  navigationItemService.setNavigationType(navigationType);

  return true;
};
